import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useHistory, NavLink } from "react-router-dom";
import $ from "jquery";
import { URL_LOGIN, URL_REGISTER } from "@components/Routes/Location";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import { Redirect } from "react-router-dom";
import {
  SELECTAGE,
  NAMEUSER,
  AVATAR,
  CTITOR,
  CTITOR2,
  DATAUSER,
  CATEGORY,
  CONDITIONS,
  CONFIRMATION,
  FINISHED,
  ROL_KID,
  ROL_TEEN,
  ROL_ADULT,
} from "./PantallasRexistro";
import Avatar from "./Avatar";
import Category from "./Category";
import Conditions from "./Conditions";
import Confirm from "./Confirm";
import CTitor from "./CTitor";
import CTitor2 from "./CTitor2";
import DataUser from "./DataUser";
import NameUser from "./NameUser";
import SelectAge from "./SelectAge";
import "./Register.css";
import {
  TENANT,
  MULTITENANT_CONSTANTS,
} from "../../../../app/src/Resources/Multitenant/tenantConstants";

import "../common/components/HeaderRegistrationComponent";
import HeaderRegistrationComponent from "../common/components/HeaderRegistrationComponent";
import { SIGNUP_MODE } from "../../../../app/src/Resources/Multitenant/tenantConstants";
import { SIGNUP_MODE_SIMPLE } from "./RegisterConstant";
import {
  turnOffLoadingActionCreator,
  turnOnLoadingActionCreator,
} from "../../../../app/src/actions/commonActions";
import WithAuthorization from "../../../../app/src/Utils/WithAuthorization";
import {
  getAvailableAvatarsService,
  getMenu,
  getUserInterestsService,
  logUserAnalyticService,
  validateEmailService,
  validateUsernameService,
  createUserService,
} from "../../../../app/src/services/services";
import {
  URL_BASE, URL_BASE_V2,
} from "../../../../app/src/services/servicesConstants";
import {
  PAGE_ID_KEY,
  PAGE_VIEW_ACTION,
  SIGN_UP_VALUE,
} from "../../../../app/src/analyticsConstants/analyticsConstants";
import {removeTokenFromCookie} from "../../../../app/src/Utils/utils";

const Register = (props) => {
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();
  const baseUrl = URL_BASE;

  const [pantalla, setPantalla] = useState(
    SIGNUP_MODE === SIGNUP_MODE_SIMPLE ? DATAUSER : SELECTAGE
  );
  const [pantallasAnteriores, setPantallasAnteriores] = useState([]);
  const [cancelado, setCancelado] = useState(false);
  const [datosState, setDatosState] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [avatares, setAvatares] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [invalidUsername, setInvalidUsername] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [msgErroRexistro, setMsgErroRexistro] = useState(null);
  const [interfaceData, setInterfaceData] = useState(null);
  const [legalConditions, setLegalConditions] = useState(null);

  const convertToMd5 = (inputString) => {
    return CryptoJS.MD5(inputString).toString();
  };

  const tenantStyles = useSelector((state) => state.commonState.tenantStyles);
  let history = useHistory();

  const turnOnLoading = () => {
    dispatch(turnOnLoadingActionCreator());
  };

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
  };

  //Limpeza de url
  if (props.location.pathname !== URL_REGISTER) {
    history.push(URL_REGISTER);
  }

  const getMenuCallback = (response) => {
    turnOffLoading();
    const legalText = response?.legal?.text;

    setInterfaceData(response);
    setLegalConditions(legalText);
    setLanguages(response?.languages);
    setDatosState((prev) => ({ ...prev, language: response?.languages[0] }));
  };

  const availableAvatarsCallback = (response) => {
    const avatars = response?.data;

    const formattedAvatars = avatars.map((avatar) => {
      return {
        id: avatar.id,
        imageId: avatar.image.id,
        url: `${baseUrl}reme` + avatar.image.data.asset_url,
      };
    });

    setAvatares(formattedAvatars);
  };

  const userInterestsCallback = (response) => {
    const interests = response?.data;

    const categories = interests.map((interest) => {
      return {
        id: interest.id,
        image: {
          height: interest.image.height,
          id: interest.image.id,
          width: interest.image.width,
          url: `${baseUrl}reme${interest.image.data.asset_url}`,
        },
        name: interest.name,
      };
    });

    setCategorias(categories);
  };

  useEffect(() => {
    turnOnLoading();
    getMenu(getMenuCallback);
    getAvailableAvatarsService(TENANT, null, availableAvatarsCallback);
    getUserInterestsService(TENANT, null, userInterestsCallback);
    logUserAnalyticService(
      TENANT,
      "anonymous",
      PAGE_VIEW_ACTION,
      PAGE_ID_KEY,
      SIGN_UP_VALUE,
      null,
      null
    );
  }, []);

  useEffect(() => {
    setMsgErroRexistro(false);
  }, [pantalla]);

  //Fluxo de pantallas
  const seguintePantalla = (rol) => {
    if (pantalla === FINISHED) {
      return <Redirect to={URL_LOGIN} />;
    }

    switch (pantalla) {
      case SELECTAGE:
        setPantallasAnteriores((pantallasAnteriores) =>
          Array.from(new Set([...pantallasAnteriores, pantalla]))
        );
        if (rol === ROL_ADULT) {
          setPantalla(DATAUSER);
        } else {
          setPantalla(NAMEUSER);
        }
        break;
      case NAMEUSER:
        setPantallasAnteriores((pantallasAnteriores) =>
          Array.from(new Set([...pantallasAnteriores, pantalla]))
        );
        setPantalla(AVATAR);
        break;
      case AVATAR:
        setPantallasAnteriores(
          Array.from(new Set([...pantallasAnteriores, pantalla]))
        );
        if (rol === ROL_ADULT || SIGNUP_MODE === SIGNUP_MODE_SIMPLE) {
          setPantalla(CATEGORY);
        } else {
          setPantalla(CTITOR);
        }
        break;
      case CTITOR:
        setPantallasAnteriores((pantallasAnteriores) =>
          Array.from(new Set([...pantallasAnteriores, pantalla]))
        );
        //setPantalla(CTITOR2)
        setPantalla(DATAUSER);
        break;
      case CTITOR2:
        setPantallasAnteriores((pantallasAnteriores) =>
          Array.from(new Set([...pantallasAnteriores, pantalla]))
        );
        setPantalla(DATAUSER);
        break;
      case DATAUSER:
        setPantallasAnteriores((pantallasAnteriores) =>
          Array.from(new Set([...pantallasAnteriores, pantalla]))
        );
        if (rol === ROL_ADULT || SIGNUP_MODE === SIGNUP_MODE_SIMPLE) {
          setPantalla(AVATAR);
        } else {
          setPantalla(CATEGORY);
        }
        break;
      case CATEGORY:
        setPantallasAnteriores((pantallasAnteriores) =>
          Array.from(new Set([...pantallasAnteriores, pantalla]))
        );
        setPantalla(CONDITIONS);
        break;
      case CONDITIONS:
        setPantallasAnteriores((pantallasAnteriores) =>
          Array.from(new Set([...pantallasAnteriores, pantalla]))
        );
        setPantalla(CONFIRMATION);
        break;
      case CONFIRMATION:
        setPantallasAnteriores((pantallasAnteriores) =>
          Array.from(new Set([...pantallasAnteriores, pantalla]))
        );
        setPantalla(FINISHED);
        break;
      default:
        setPantalla(SELECTAGE);
        break;
    }
  };

  const anteriorPantalla = () => {
    const pas = [...pantallasAnteriores];
    if (pas && pas.length > 0) {
      //Reseteo de datos ao cambiar de idade
      if (
        pas[pas.length - 1] ===
        (SIGNUP_MODE === SIGNUP_MODE_SIMPLE ? DATAUSER : SELECTAGE)
      ) {
        resetearDatos();
      }

      setPantalla(pas[pas.length - 1]);
      pas.splice(pas.length - 1, 1);
      setPantallasAnteriores(pas);
    } else {
      resetearDatos();
      setPantalla(SIGNUP_MODE === SIGNUP_MODE_SIMPLE ? DATAUSER : SELECTAGE);
      setPantallasAnteriores([]);
    }
  };

  const asignarRole = (rol) => {
    setDatosState((prev) => ({ ...prev, rol: rol }));
    switch (rol) {
      case ROL_KID:
        setDatosState((prev) => ({ ...prev, rolObject: { maxAge: 9 } }));
        break;
      case ROL_TEEN:
        setDatosState((prev) => ({
          ...prev,
          rolObject: { minAge: 10, maxAge: 13 },
        }));
        break;
      case ROL_ADULT:
        setDatosState((prev) => ({ ...prev, rolObject: { minAge: 14 } }));
        break;
      default:
        break;
    }

    if (rol === ROL_KID) {
      $("#root").addClass("Kid");
    } else {
      $("#root").removeClass("Kid");
    }

    seguintePantalla(rol);
  };

  const resetearDatos = () => {
    setDatosState({});
  };

  if (!initialized || !keycloak.clientId) {
    return <div className="loading" />;
  }

  if (keycloak && keycloak.authenticated) {
    keycloak.logout();
    removeTokenFromCookie();
    return <div className="loading" />;
  }

  if (cancelado) {
    return <Redirect to={URL_LOGIN} />;
  }

  if (pantalla === FINISHED) {
    return <Redirect to={URL_LOGIN} />;
  }

  const ctrlSetFirstName = (e) => {
    if (e.trim !== "") {
      setDatosState((prev) => ({ ...prev, firstName: e }));
    }
  };

  const ctrlSetLastName = (e) => {
    if (e.trim !== "") {
      setDatosState((prev) => ({ ...prev, lastName: e }));
    }
  };

  const ctrlSetBirthday = (date) => {
    setDatosState((prev) => ({ ...prev, birthdate: new Date(date) }));
  };

  const validateUsernameCallback = (response) => {
    if (response.data.exists === true) {
      setInvalidUsername(true);
    } else {
      const parsedResponse = JSON.parse(response.data.error_error.http_body);

      if (parsedResponse.exists === false) {
        setDatosState((prev) => ({ ...prev, username: response.username }));
      }
    }
  };

  const ctrlSetUsername = (e) => {
    setInvalidUsername(false);
    validateUsernameService(TENANT, e, validateUsernameCallback);
  };

  const validateEmailCallback = (response) => {
    if (response.data.exists === true) {
      setInvalidEmail(true);
    } else {
      const parsedResponse = JSON.parse(response.data.error_error.http_body);

      if (parsedResponse.exists === false) {
        setDatosState((prev) => ({ ...prev, email: response.email }));
      }
    }
  };

  const ctrlSetEmail = (e) => {
    setInvalidEmail(false);
    let re = /^\S+@\S+\.\S+$/;

    if (re.test(e)) {
      validateEmailService(TENANT, e, validateEmailCallback);
    } else {
      setInvalidEmail(true);
    }
  };

  const ctrlSetCredentials = (p) => {
    const validatePassword = (password) => {
      const validator =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{8,}$/;
      return validator.test(password);
    };

    const isValid = validatePassword(p);

    if (isValid) {
      setDatosState((prev) => ({ ...prev, credentials: p }));
      setInvalidPassword(false);
    } else {
      setInvalidPassword(true);
    }
  };

  const ctrlSetAvatar = (a) => {
    setDatosState((prev) => ({ ...prev, avatar: a.id }));
  };

  const ctrlAddInterest = (c) => {
    setSelectedCategories((prev) => [...prev, c]);
  };

  const createUserCallback = (response) => {
    if (response.error_error) {
      setMsgErroRexistro(true);

      setTimeout(() => {
        history.push(URL_LOGIN);
      }, 3500);
    } else {
      setPantallasAnteriores((pantallasAnteriores) =>
        Array.from(new Set([...pantallasAnteriores, CONDITIONS]))
      );

      setPantalla(CONFIRMATION);
    }
  };

  const createUserErrorCallback = () => {
    setMsgErroRexistro(true);

    setTimeout(() => {
      history.push(URL_LOGIN);
    }, 3500);
  };

  const ctrlAcceptLegalConditions = (c) => {
    let bodyRequest;

    const formattedCategories = selectedCategories.map(
      (category) => category.id
    );
    const hash = `${MULTITENANT_CONSTANTS[TENANT].secret}${datosState.username}${datosState.credentials}`;
    const codedHash = convertToMd5(hash);

    const { categorias, rol, rolObject, ...rest } = datosState;

    if (SIGNUP_MODE === SIGNUP_MODE_SIMPLE) {
      bodyRequest = {
        ...rest,
        birthday: MULTITENANT_CONSTANTS[TENANT].defaultBirthday,
        acceptedLegalVersion: interfaceData?.legal?.version,
        interests: !!formattedCategories ? formattedCategories : [],
        hash: codedHash,
      };
    } else {
      if (datosState.rol === ROL_KID || datosState.rol === ROL_TEEN) {
        bodyRequest = {
          ...rest,
          tutor: datosState.email,
          acceptedLegalVersion: interfaceData?.legal?.version,
          interests: !!formattedCategories ? formattedCategories : [],
          hash: codedHash,
        };
      } else {
        bodyRequest = {
          ...rest,
          acceptedLegalVersion: interfaceData?.legal?.version,
          interests: !!formattedCategories ? formattedCategories : [],
          hash: codedHash,
        };
      }
    }

    createUserService(
      TENANT,
      bodyRequest,
      createUserCallback,
      createUserErrorCallback
    );
  };

  const validar = (password2) => {
    if (password2 === datosState.credentials) {
      setInvalidPassword(false);
    } else {
      setInvalidPassword(true);
      return;
    }

    // Username
    if (!datosState.username) {
      setInvalidUsername(true);
      return;
    } else {
      setInvalidUsername(false);
    }

    //Email
    if (!datosState.email && SIGNUP_MODE !== SIGNUP_MODE_SIMPLE) {
      setInvalidEmail(true);
      return;
    } else {
      setInvalidEmail(false);
    }

    seguintePantalla();
  };

  return (
    <div
      className={"Register " + (datosState?.rol ? datosState?.rol : "")}
      style={{
        "background-image": "url(" + tenantStyles?.backgroundRegister + ")",
      }}
    >
      <div className="App-container">
        <div className="logo">
          <NavLink to={URL_LOGIN}>
            <div className="Logo-Barra" alt="logo" />
          </NavLink>
        </div>
        <div className="Register-Container">
          <HeaderRegistrationComponent
            pageNumber={pantallasAnteriores.length}
            pantalla={pantalla}
            rol={datosState?.rol}
            language={datosState?.language}
            languages={languages}
          />
          {(!pantalla || pantalla === SELECTAGE) && (
            <SelectAge
              asignarRole={asignarRole}
              ctrlSetBirthday={ctrlSetBirthday}
              datos={datosState}
            />
          )}
          {pantalla === NAMEUSER && (
            <NameUser
              datos={datosState}
              setDatosState={setDatosState}
              seguintePantalla={seguintePantalla}
              anteriorPantalla={anteriorPantalla}
              ctrlSetUsername={ctrlSetUsername}
              invalidUsername={invalidUsername}
            />
          )}
          {pantalla === AVATAR && (
            <Avatar
              datos={datosState}
              seguintePantalla={seguintePantalla}
              anteriorPantalla={anteriorPantalla}
              ctrlSetAvatar={ctrlSetAvatar}
              avatares={avatares}
            />
          )}
          {pantalla === CTITOR && (
            <CTitor
              datos={datosState}
              seguintePantalla={seguintePantalla}
              anteriorPantalla={anteriorPantalla}
            />
          )}
          {pantalla === CTITOR2 && (
            <CTitor2
              datos={datosState}
              seguintePantalla={seguintePantalla}
              anteriorPantalla={anteriorPantalla}
            />
          )}
          {pantalla === DATAUSER && (
            <DataUser
              datos={datosState}
              setDatosState={setDatosState}
              seguintePantalla={seguintePantalla}
              anteriorPantalla={anteriorPantalla}
              ctrlSetFirstName={ctrlSetFirstName}
              ctrlSetLastName={ctrlSetLastName}
              ctrlSetBirthday={ctrlSetBirthday}
              ctrlSetUsername={ctrlSetUsername}
              ctrlSetEmail={ctrlSetEmail}
              ctrlSetCredentials={ctrlSetCredentials}
              invalidUsername={invalidUsername}
              invalidPassword={invalidPassword}
              invalidEmail={invalidEmail}
              followButtonDisabled={
                invalidEmail || invalidUsername || invalidPassword
              }
              validar={validar}
            />
          )}
          {pantalla === CATEGORY && (
            <Category
              datos={datosState}
              seguintePantalla={seguintePantalla}
              anteriorPantalla={anteriorPantalla}
              categorias={categorias}
              ctrlAddInterest={ctrlAddInterest}
              setDatosState={setDatosState}
            />
          )}
          {pantalla === CONDITIONS && (
            <Conditions
              legalConditions={legalConditions}
              seguintePantalla={seguintePantalla}
              anteriorPantalla={anteriorPantalla}
              msgErroRexistro={msgErroRexistro}
              setMsgErroRexistro={setMsgErroRexistro}
              ctrlAcceptLegalConditions={ctrlAcceptLegalConditions}
            />
          )}
          {pantalla === CONFIRMATION && (
            <Confirm
              datos={datosState}
              seguintePantalla={seguintePantalla}
              anteriorPantalla={anteriorPantalla}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WithAuthorization(Register);

import React, { useContext, useEffect, useRef, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useSelector } from "react-redux";

import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useTranslation } from "react-i18next";
import "../../Menu.css";
import { Notifications } from "../../Context/Notifications";
import MenuSectionsComponent from "./components/MenuSectionsComponent";
import UserSectionsComponent from "./components/UserSectionsComponent";
import LateralMenu from "./components/LateralMenu";
import { URL_HOME } from "../../../../Components/Routes/Location";                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
import NotificationMenuComponent from "./components/NotificationMenuComponent";
import { CONFIGURATION_SECTION, LOGOUT_SECTION, RANKING_SECTION, STUDIO_SECTION } from "../../MenuConstants";
import iconDiputacion from "../../../../Resources/Images/dc_negativo.png";
import default_section_icon from "../../../../Resources/Images/default_section_icon.svg";
import { ENVIRONMENT, MULTITENANT_CONSTANTS, TENANT } from "../../../../Resources/Multitenant/tenantConstants";
import { checkMuiIconService } from "../../../../services/services";
import {URL_BASE_IMAGE} from "../../../../services/servicesConstants";
import {removeTokenFromCookie,generateCSSFilter } from "../../../../Utils/utils";
import {MAX_NOTIFICATIONS,DISPLAY_NOTIFICATIONS } from "../../../../Utils/Constants";

const MenuComponent = ({ handleToggleNotifications, renderMobileVersion, handleChangeSection }) => {
  useEffect(() => {
    setTimeout(() => {
      const sidebarHoverColor = getComputedStyle(document.documentElement)
        .getPropertyValue("--sidebarHoverIconColor")
        .trim();
  
  
      if (sidebarHoverColor && /^#[0-9A-Fa-f]{6}$/.test(sidebarHoverColor)) {
        const cssFilter = generateCSSFilter(sidebarHoverColor); // Genera el filtro
        // Aplica el filtro como variable CSS
        document.documentElement.style.setProperty("--iconHoverFilter", cssFilter);
      } else {
        console.warn("El color no es válido o está undefined:", sidebarHoverColor);
      }
    }, 500);
  }, []);
  
  const DEFAULT_ICON = "play-circle-outline";

  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  const [showMenu, setShowMenu] = useState(false);
  const [displayNotifications, setDisplayNotifications] = useState(false);
  const [numberNewNotifications, setNumberNewNotifications] = useState(0);
  const iconName = useRef({});
  const [reloadIconFlag, setReloadIconFlag] = useState(false);

  const DIPUTACION = "cinfo-deputacion";

  const { notifications } = useContext(Notifications);

  const { sectionsMenu, userData } = useSelector((state) => state.commonState);

  useEffect(() => {
    setNumberNewNotifications(getNumberNewNotifications());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  const changeSection = (s) => {
    setShowMenu(false);
    handleChangeSection(s);
  };

  const displayMenu = () => {
    if (showMenu) {
      setShowMenu(false);
    } else {
      setShowMenu(true);
    }
    if (displayNotifications) {
      setDisplayNotifications(false);
    }
  };

  const onContainerClick = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setShowMenu(false);
    }
  };

  const logout = async () => {
    await removeTokenFromCookie();
    await keycloak?.logout({ redirectUri: window.location.origin + URL_HOME });
  };

  const handleToggleNotificationMenu = () => {
    setDisplayNotifications((displayNotifications) => !displayNotifications);

    if (showMenu) {
      setShowMenu(false);
    }
  };

  const handleCloseNotificationMenu = (event) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setDisplayNotifications(false);
    }
  };

  const studioUrl = MULTITENANT_CONSTANTS[TENANT]?.studioUrl[ENVIRONMENT];

  const studioSection = !!studioUrl ? STUDIO_SECTION(studioUrl) : false;
  const configurationSection = CONFIGURATION_SECTION;
  const logOutSection = LOGOUT_SECTION;
  const rankingSection = RANKING_SECTION;

  const getNumberNewNotifications = () => {
    const newNotifications = notifications ? notifications.filter((notification) => notification.isNew) : [];
    return newNotifications.length > 0 ? newNotifications.length : 0;
  };

  const getClassName = () => {
    return numberNewNotifications > 0 ? "NotificationsContainer Signal" : "NotificationsContainer";
  };

  const checkMuiIconErrorCallback = (icon) => {
    iconName.current = { ...iconName.current, [icon]: DEFAULT_ICON };
  };

  useEffect(() => {
    setReloadIconFlag(!reloadIconFlag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconName.current]);

  const handleImageError = (e) => {
    e.target.src = default_section_icon;
  };



  const getIconSection = (icon) => {
    if (!!icon?.data?.asset_url) {
      return <img src={`${URL_BASE_IMAGE}${icon?.data?.asset_url}`} className={"CustomIcon"} alt={icon?.title} onError={(e) => handleImageError(e)}/>;
    } else if (icon.startsWith("https://")) {
      return <img src={icon} className={"CustomIcon"} alt={icon?.title} onError={(e) => handleImageError(e)}/>;
    } else if (icon === "null") {
      return <ion-icon name="arrow-forward-outline" alt={"Icono"} />;
    } else if (icon === DIPUTACION) {
      return <img src={iconDiputacion} alt={icon} />;
    } else if (!iconName.current.hasOwnProperty(icon)) {
      checkMuiIconService(icon, () => checkMuiIconErrorCallback(icon));
      return <ion-icon name={icon} alt={icon} />;
    } else {
      return <ion-icon name={iconName?.current[icon]} alt={iconName?.current} />;
    }
  };

  const [configurationIcon, setConfigurationIcon] = useState(<ion-icon name={configurationSection.section.iconId} />);
  const [logoutIcon, setLogoutIcon] = useState(
    <ion-icon name={logOutSection.section.iconId} class={MULTITENANT_CONSTANTS[TENANT].menuClassLogout} />,
  );

  useEffect(() => {
    if (configurationSection.section.imgIcon) setConfigurationIcon(configurationSection.section.imgIcon);
    if (logOutSection.section.imgIcon) setLogoutIcon(logOutSection.section.imgIcon);
  }, [configurationSection, logOutSection]);

  return (
    <div className="Menu">
      {showMenu ? (
        <LateralMenu
          onContainerClick={onContainerClick}
          displayMenu={displayMenu}
          sections={sectionsMenu}
          rankingSection={rankingSection}
          studioSection={studioSection}
          configurationSection={configurationSection}
          logOutSection={logOutSection}
          changeSection={changeSection}
          logout={logout}
          handleToggleNotificationMenu={handleToggleNotificationMenu}
          notifications={notifications}
          getIconSection={getIconSection}
          configurationIcon={configurationIcon}
          logoutIcon={logoutIcon}
          reloadIconFlag={reloadIconFlag}
        />
      ) : (
        <div className="MenuLateral">
          <div className={"MenuSection"} onClick={displayMenu}>
            <ion-icon name="menu" />
          </div>
          {!!sectionsMenu.length && (
            <div className={"MenuContainer"}>
              <MenuSectionsComponent
                sections={sectionsMenu}
                changeSection={changeSection}
                getIconSection={getIconSection}
                reloadIconFlag={reloadIconFlag}
              />
              <div>
              {!!userData.username && (
                  <OverlayTrigger
                    delay={{ hide: 300, show: 300 }}
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-notification-bell">
                        {t("menu.notificationBellTooltip")}
                      </Tooltip>
                    }
                  >
                <div className={getClassName()} onClick={handleToggleNotificationMenu}>
                  <div className="NotificationBellWrapper">
                    <ion-icon name="notifications" className="NotificationBell" />
                    {numberNewNotifications > 0 && (
                      <div className="NotificationDot">
                        {numberNewNotifications > MAX_NOTIFICATIONS ? DISPLAY_NOTIFICATIONS : numberNewNotifications}
                      </div>
                    )}
                  </div>
                </div>
                  </OverlayTrigger>
                )}
                {!!userData.username && (
                  <UserSectionsComponent
                    studioSection={studioSection}
                    configurationSection={configurationSection}
                    logOutSection={logOutSection}
                    changeSection={changeSection}
                    logout={logout}
                    configurationIcon={configurationIcon}
                    logoutIcon={logoutIcon}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {displayNotifications && (
        <NotificationMenuComponent
          handleCloseNotificationMenu={handleCloseNotificationMenu}
          notifications={notifications}
          handleToggleNotifications={handleToggleNotifications}
          renderMobileVersion={renderMobileVersion}
        />
      )}
    </div>
  );
};

export default MenuComponent;

import React, { useEffect, useState, useRef } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { URL_LOGIN } from "@components/Routes/Location";
import AlertNotification from "@components/GUI/Alert/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorPlayerModalComponent from "./ErrorPlayerModalComponent";
import "./Player.css";
import "@videojs/themes/dist/city/index.css";
import "@videojs/themes/dist/fantasy/index.css";
import "@videojs/themes/dist/forest/index.css";
import "@videojs/themes/dist/sea/index.css";
import QRModalComponent from "../../../../app/src/Components/common/QRModalComponent";
import {
  saveContentHashActionCreator,
  saveContentTypeActionCreator,
  turnOffLoadingActionCreator,
  turnOnLoadingActionCreator,
} from "../../../../app/src/actions/commonActions";
import { PLAYER_TYPE_AR } from "./PlayerConstants";
import ErrorMessageModalComponent from "../../../../app/src/Components/common/ErrorMessageModalComponent";
import { getContentPermissions } from "../../../../app/src/services/services";
import {
  TENANT,
} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import { useKeycloak } from "@react-keycloak/web";
import {
  URL_BASE,
} from "../../../../app/src/services/servicesConstants";
import {getToken} from "../../../../app/src/Utils/utils";
import {LOAD_CONTENT_PLAYER, REDIRECT_TO_LOGIN} from "../../../../detail/src/Pages/DetailPage/VideoDetailPageConstants";

function Player({ id }) {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const userToken = getToken(keycloak);
  const baseUrl = URL_BASE;

  const contentHash = useSelector((state) => state.commonState.contentHash);
  const contentType = useSelector((state) => state.commonState.contentType);

  const [player, setPlayer] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [timeoutError, setTimeoutError] = useState(false);
  const [errorUserSessionExpired, setErrorUserSessionExpired] = useState(false);
  const [errorVideoUrlUnavailable, setErrorVideoUrlUnavailable] =
    useState(false);
  const [displayQRModal, setDisplayQRModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [hash, setHash] = useState(null);
  const [alloweb, setAllowed] = useState(false);
  const iframeRef = useRef(null);

  const turnOnLoading = () => {
    dispatch(turnOnLoadingActionCreator());
  };

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
  };

  const contentPermissionsCallback = (response) => {
    setAllowed(response.allowed);
  };

  useEffect(() => {
    if (!contentHash) {
      getContentPermissions(
        TENANT,
        id,
        userToken || undefined,
        contentType,
        contentPermissionsCallback
      );
    }
    return () => {
        dispatch(saveContentHashActionCreator(null));
        dispatch(saveContentTypeActionCreator(null));
    };
  }, []);

  useEffect(() => {
    const formattedModel = {};
    turnOnLoading();
    const availableHash = !!hash ? hash : contentHash;
    let videoUrl = `${baseUrl}player/${TENANT}/${id}`;

    const params = new URLSearchParams();
    if (!!userToken) {
      params.append('token', userToken);
    }
    if (!!availableHash) {
      params.append('hash', availableHash);
    }
    const currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.forEach((value, key) => {
      params.append(key, value);
    });
    if (!!params.toString()) {
      videoUrl = `${videoUrl}?${params.toString()}`;
    }

    formattedModel.videoUrl = videoUrl;
    formattedModel.contentType = contentType;

    setPlayer(formattedModel);
  }, [hash, userToken, contentHash]);

  useEffect(() => {
    if (!!player?.videoUrl) {
      turnOffLoading();
    }
  }, [player]);

  const handleCloseQRModal = () => {
    setDisplayQRModal((displayQRModal) => !displayQRModal);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isLoading) {
        setTimeoutError(true);
        setIsLoading(false);
      }
    }, 20000);

    return () => clearTimeout(timeout);
  }, [isLoading]);

  const actionToLoaded = () => {
    setTimeoutError(false);
    setIsLoading(false)
  }

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.onload = () => actionToLoaded();
      iframeRef.current.onerror = () => {
        setIsLoading(false);
        setError(true);
        setTimeoutError(true);
      };
    }
  }, [player?.videoUrl]);

  return (
    <>
      {isLoading && (
        <div className="spinner">
          <CircularProgress size={50} thickness={4} />
        </div>
      )}
      {timeoutError && (
        <ErrorPlayerModalComponent
          refModal={null}
          handleCloseModal={() => setTimeoutError(false)}
        />
      )}
        <div className={"Player"}>
          {player?.videoUrl && (
              <iframe
                  ref={iframeRef}
                  src={player.videoUrl}
                  className="PlayerIframe"
                  allow="autoplay; encrypted-media; fullscreen"
                  id="player"
                  width="100%"
                  height="100%"
                  style={{ position: "relative", display: isLoading ? "none" : "initial" }}
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowfullscreen="true"
              />
          )}
        </div>

      {messageModal && <ErrorMessageModalComponent onClose={() => setMessageModal(false)} />}
      {displayQRModal && player?.shareUrl && (
        <QRModalComponent
          url={player.shareUrl}
          goBack={true}
          handleCloseQRModal={handleCloseQRModal}
        />
      )}
      {errorUserSessionExpired && <Redirect to={URL_LOGIN} />}
      {error || (errorVideoUrlUnavailable && <AlertNotification />)}
    </>
  );
}

export default Player;

import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import "../../../Menu.css";
import { checkMenuActive, getSectionName } from "../../../../../Utils/utils";
import {MAX_NOTIFICATIONS,DISPLAY_NOTIFICATIONS,ACTIVE_CLASS } from "../../../../../Utils/Constants";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../../../Resources/Multitenant/tenantConstants";

const LateralMenu = ({
  onContainerClick,
  displayMenu,
  sections,
  changeSection,
  studioSection,
  configurationSection,
  logOutSection,
  logout,
  handleToggleNotificationMenu,
  notifications,
  getIconSection,
  configurationIcon,
  logoutIcon,
  reloadIconFlag,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const userData = useSelector((state) => state.commonState.userData);
  const sectionSelected = useSelector((state) => state.commonState.sectionSelected);

  useEffect(() => { }, [reloadIconFlag]);

  const handleRedirectToStudio = () => {
    window.open(studioSection.url, "_blank");
  };
  const unreadNotifications = notifications?.filter((notification) => notification.isNew).length || 0;
  const notificationDisplay = unreadNotifications > MAX_NOTIFICATIONS? DISPLAY_NOTIFICATIONS : unreadNotifications;

  return (
    <div className="MenuLateralResContainer" onClick={onContainerClick}>
      <div className="MenuLateralRes ">
        <div className={"MenuSection"}>
          <div className={"PecharMenu"} onClick={displayMenu}>
            <ion-icon name="close" />
          </div>
        </div>
        <div className={"MenuContainer"}>
          <div>
            {sections?.map((a, index) => {
              const isActive = location.pathname === a.url;
              return (
              <div key={a.section.name + "-" + index}>
                <NavLink
                  to={{
                    pathname: a.url,
                    aboutProps: {
                      dinamic: a.section.screenId,
                    },
                  }}
                  onClick={() => changeSection(a.section)}
                  className={`MenuSectionRes ${a.section.iconId} ${isActive ? ACTIVE_CLASS : ""}`}
                  key={a.section.iconId}
                  isActive={(match, location) =>
                    checkMenuActive(match, location, a, sectionSelected, sections, sections)
                  }
                >
                  {getIconSection(a.section?.iconsvg || a.section.iconId)}
                  <span className="TextRes">{getSectionName(a.section)}</span>
                </NavLink>              
              </div>
              );
            })}
          </div>
          <div className="MenuInferiorIcons">
            {!!userData.username && (
            <div
              className={`MenuSectionRes notifications-section ${location.pathname === "/notifications" ? ACTIVE_CLASS : ""
                }`}
              onClick={handleToggleNotificationMenu}
            >
              <div className="NotificationBellWrapper">
                <ion-icon name="notifications" className="NotificationBell" />
                {unreadNotifications > 0 && (
                  <div className="NotificationDoto">{notificationDisplay}</div>
                )}
              </div>
              <span className="TextRes">{t("notifications")}</span>
                  </div>
              )}
            {studioSection.url && !!userData.username && (
                  <div onClick={handleRedirectToStudio}>
                    <p className={"MenuSectionRes " + studioSection.section.iconId}>
                      <ion-icon name={studioSection.section.iconId}/>
                      <span className="TextRes">{t(studioSection.section.name)}</span>
                    </p>
                  </div>
              )}
            {configurationSection && !!userData.username && (
                   <NavLink
                   to={{ pathname: configurationSection.url.toLowerCase() }}
                   onClick={() => changeSection(configurationSection.section)}
                   className={`MenuSectionRes ${configurationSection.section.iconId}`}
                   key={configurationSection.section.iconId}
                 >
                   {configurationIcon}
                   <span className="TextRes">{t(configurationSection.section.name)}</span>
                 </NavLink>
               )}
            {logOutSection && !!userData.username && (
               <div onClick={logout}>
                <p className={
                        "MenuSectionRes " + logOutSection.section.iconId + " " + MULTITENANT_CONSTANTS[TENANT].menuClassLogout
                    }>
                  <ion-icon name={logOutSection.section.iconId} />
                  <span className="TextRes">{t(logOutSection.section.name)}</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LateralMenu;

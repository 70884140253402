import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Redirect } from "react-router-dom";
import {URL_HOME} from "../../../../app/src/Components/Routes/Location";
import "./UserProfile.css";
import iconTrophy from "../../../../app/src/Resources/Images/icon_trophy.svg";
import {
  displayOnBoardingActionCreator, saveUserDataActionCreator,
  togglePasswordModeActionCreator,
  turnOffLoadingActionCreator,
} from "../../../../app/src/actions/commonActions";
import {
  INTERFACE_LOGGED_IN,
  INTERFACE_MAIN,
  LOCAL_STORAGE_KEY__LOCALE,
} from "../../../../app/src/Utils/Constants";
import SelectCategoriesComponent from "./PerfilComponents/SelectCategoriesComponent";
import AchievementComponent from "./RankingComponents/components/AchievementsComponent";
import PasswordModalComponent from "./PerfilComponents/PasswordModalComponent";
import DesactivateUserModalComponent from "./PerfilComponents/DesactivateUserModalComponent";
import AlertNotification from "../../../../app/src/Components/GUI/Alert/Alert";
import AvatarModalComponent from "./PerfilComponents/AvatarModalComponent";
import UserInfoComponent from "./PerfilComponents/UserInfoComponent";
import UserButtonOptionsComponent from "./PerfilComponents/UserButtonOptionsComponent";
import TermsModalComponent from "./PerfilComponents/TermsModalComponent";
import {
  MULTITENANT_CONSTANTS,
  TENANT,
} from "../../../../app/src/Resources/Multitenant/tenantConstants";
import {
  getFileService,
  getMenu,
  getProductsDashboardService,
  updateUserDataService, updateUserPasswordService,
  deleteUserService,
} from "../../../../app/src/services/services";
import ErrorMessageModalComponent from "../../../../app/src/Components/common/ErrorMessageModalComponent";
import { PASSWORD_VALIDATION_REGEX } from "./UserProfileConstants";
import { URL_RANKING } from "../../../../app/src/Components/Routes/Location";
import WithAuthorization from "../../../../app/src/Utils/WithAuthorization";
import {URL_BASE_IMAGE} from "../../../../app/src/services/servicesConstants";

import CustomModal from "./CustomModal";
const UserProfile = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {scrollRef} = props;
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const refPass = useRef(null);
  const refAvatar = useRef(null);
  const refTerms = useRef(null);
  const refDeactivate = useRef(null);

  const locale = localStorage.getItem(LOCAL_STORAGE_KEY__LOCALE);

  const [userProfile, setUserProfile] = useState({});
  const [editUserInfo, setEditUserInfo] = useState(false);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const [passwordModal, setPasswordModal] = useState(false);
  const [wrongCurrentPassword, setWrongCurrentPassword] = useState(false);
  const [invalidNewPassword, setInvalidNewPassword] = useState(false);
  const [failedNewPassword, setFailedNewPassword] = useState(false);
  const [passwordsMustMatch, setPasswordsMustMatch] = useState(false);
  const [samePassword, setSamePassword] = useState(false);
  const [successEdit, setSuccessEdit] = useState(false);

  const invalidPasswordOptions = [
    wrongCurrentPassword,
    invalidNewPassword,
    failedNewPassword,
    passwordsMustMatch,
    samePassword,
  ];

  const [disabledPassEdit, setDisabledPassEdit] = useState(null);

  const [avatarModal, setAvatarModal] = useState(false);
  const [terms, setTerms] = useState(null);
  const [termsModal, setTermsModal] = useState(null);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [tutorialUrl, setTutorialUrl] = useState(null);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorSubText, setErrorSubText] = useState("");
  const [username] = useState("usuarioDemo");

  const { userData } = useSelector((state) => state.commonState);
  const { keycloak, initialized } = useKeycloak();
  const isLoginRequired = MULTITENANT_CONSTANTS[TENANT].showLoginButton;

  if (!isLoginRequired) {
    return <Redirect to={URL_HOME} />;
  }

  const handleDisplayOnBoarding = (value) => {
    dispatch(displayOnBoardingActionCreator(value));
  };

  useEffect(() => {
    if (passwordModal) {
      setDisabledPassEdit(
        !currentPassword ||
          !newPassword1 ||
          !newPassword2 ||
          invalidPasswordOptions.filter((value) => value).length
      );

      if (
        (userProfile?.credentials &&
          currentPassword !== userProfile?.credentials) ||
        newPassword1 !== currentPassword
      ) {
        setSamePassword(false);
      } else if (
        (userProfile?.credentials &&
          currentPassword === userProfile?.credentials) ||
        (newPassword1 && currentPassword && newPassword1 === currentPassword)
      ) {
        setSamePassword(true);
      }

      if (newPassword1 && newPassword2 && newPassword1 === newPassword2) {
        setPasswordsMustMatch(false);
      } else if (
        newPassword1 &&
        newPassword2 &&
        newPassword1 !== newPassword2
      ) {
        setPasswordsMustMatch(true);
      }
    }
    scrollRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }, [
    currentPassword,
    newPassword1,
    newPassword2,
    invalidPasswordOptions,
    passwordModal,
  ]);

  useEffect(() => {
    if (!passwordModal) {
      resetPasswordState();
    }
  }, [passwordModal]);

  useEffect(() => {
    if (userProfile !== null) {
      setFirstName(userProfile?.firstName);
      setLastName(userProfile?.lastName);
    }
  }, [userProfile]);

  useEffect(() => {
    if (successEdit) {
      setTimeout(() => handleSetSuccessEdit(false), 2000);
    }
  }, [successEdit]);

  const handleSetCurrentPassword = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handleSetNewPassword1 = (event) => {
    setNewPassword1(event.target.value);
  };

  const handleSetNewPassword2 = (event) => {
    setNewPassword2(event.target.value);
  };

  const handleSetFirstName = (event) => {
    setFirstName(event.target.value);
  };

  const handleSetLastName = (event) => {
    setLastName(event.target.value);
  };

  const handleEditUserInfo = () => {
    setEditUserInfo((editUserInfo) => !editUserInfo);
  };

  const updateUserDataCallback = (data) => {
    const formattedModel = {
      ...userProfile,
      firstName: data.firstname,
      lastName: data.lastname,
    };
    setUserProfile(formattedModel);
  };

  const updateUserDataError = () => {
    setErrorText("userupdate.errorupdatinguserdata");
    setFirstName(userProfile.firstName);
    setLastName(userProfile.lastName);
    setShowErrorModal(true);
  };

  const handleSendUserData = () => {
    if (
      firstName !== userProfile.firstName ||
      lastName !== userProfile.lastName
    ) {
      const body = {
        firstName: firstName ? firstName : userProfile.firstName,
        lastName: lastName ? lastName : userProfile.lastName,
      };
      updateUserDataService(
        userData.appname,
        userData.username,
        keycloak?.token,
        updateUserDataCallback,
        updateUserDataError,
        body
      );
      setEditUserInfo(false);
    }
  };

  const resetPasswordState = () => {
    setFailedNewPassword(false);
    setWrongCurrentPassword(false);
    setSamePassword(false);
    setPasswordsMustMatch(false);
    setInvalidNewPassword(false);
    setSuccessEdit(false);

    setCurrentPassword("");
    setNewPassword1("");
    setNewPassword2("");
  };

  const handleCheckNewPassword = (password) => {
    setInvalidNewPassword(PASSWORD_VALIDATION_REGEX.test(password));
  };

  const updateUserCredentialsCallback = (data) => {
    if (data?.error_error?.http_status_code === 401) {
      setErrorText("userupdate.errorupdatingpassword");
      setErrorSubText("userupdate.wrongCurrentPassword");
      setFirstName(userProfile.firstName);
      setLastName(userProfile.lastName);
      setShowErrorModal(true);
    } else {
      const formattedModel = {
        ...userProfile,
        firstName: data.firstname,
        lastName: data.lastname,
      };
      setUserProfile(formattedModel);
    }
  };

  const updateUserCredentialsError = () => {
    setErrorText("userupdate.errorupdatingpassword");
    setFirstName(userProfile.firstName);
    setLastName(userProfile.lastName);
    setShowErrorModal(true);
  };

  const handleSendUserCredentials = (current, new1, new2) => {
    const body = {
      newPassword: new1,
      confirmation: new2,
      currentPassword: current,
    };
    updateUserPasswordService(
      userData.appname,
      userData.username,
      keycloak?.token,
      (data) => {
        updateUserCredentialsCallback(data);
        handlePasswordModal(false);
      },
      (error) => {
        updateUserCredentialsError(error);
      },
      body
    );
  };

  const handleTermsModal = () => {
    setTermsModal((termsModal) => !termsModal);
  };

  const handlePasswordModal = (value) => {
    togglePasswordMode();
    setPasswordModal(value);
  };
  const handleDeactivateUserModal = (value) => {
    setDeactivateModal(value);
  };
  const handleDeactivateUser = () => {
    setDeactivateModal(false);

    deleteUserService(
      userData.appname,
      userData.username,
      keycloak?.token,
      () => {
        setModalMessage(t("perfil.deactivate.success"));
        setShowModal(true);
        setTimeout(() => {
          if (keycloak) {
            keycloak.logout({
              redirectUri: `${window.location.origin}/home`,
            });
          }
        }, 3000);
      },
      (error) => {
        console.error("Error al eliminar el usuario:", error);
        setModalMessage(t("perfil.deactivate.error"));
        setShowModal(true);

        setTimeout(() => {
          setShowModal(false);
        }, 3000);
      }
    );
  };

  const handleSetWrongCurrentPassword = () => {
    if (wrongCurrentPassword) {
      setWrongCurrentPassword(false);
    }
  };

  const handleAvatarModal = () => {
    setAvatarModal((avatarModal) => !avatarModal);
  };

  const handleSetSuccessEdit = (value) => {
    setSuccessEdit(value);
  };

  const updateUserAvatarCallback = (data) => {
    getFileService(
      MULTITENANT_CONSTANTS[TENANT].tenantServer,
      data.avatar,
      keycloak?.token,
      getFileCallback
    );
    const updatedUserData = {
      ...userData,
      avatar: data.avatar,
    };
    dispatch(saveUserDataActionCreator(updatedUserData));
    handleAvatarModal();
  };

  const updateUserAvatarError = () => {
    setErrorText("userupdate.errorupdatinguseravatar");
    setShowErrorModal(true);
    handleAvatarModal();
  };

  const handleSendAvatar = (value) => {
    const body = {
      avatar: value.id,
    };
    updateUserDataService(
      userData.appname,
      userData.username,
      keycloak?.token,
      updateUserAvatarCallback,
      updateUserAvatarError,
      body
    );
  };

  const turnOffLoading = () => {
    dispatch(turnOffLoadingActionCreator());
  };

  const togglePasswordMode = () => {
    dispatch(togglePasswordModeActionCreator());
  };

  const getMenuCallback = (response) => {
    const mainInterface = response?.data?.find(
      (item) =>
        item.title === INTERFACE_MAIN || item.title === INTERFACE_LOGGED_IN
    ) || response;

    if (!!mainInterface?.tutorialUrl) {
      setTutorialUrl(mainInterface.tutorialUrl);
    } else if (!!MULTITENANT_CONSTANTS[TENANT].tutorialUrl){
      setTutorialUrl(MULTITENANT_CONSTANTS[TENANT].tutorialUrl);
    }

    if (!!mainInterface?.legal?.text) {
      let legalText = mainInterface.legal.text;

      const translatedText = mainInterface.legal.translations?.find(
        (item) => item.language === locale
      );

      if (!!translatedText) {
        legalText = translatedText?.text || translatedText;
      }

      setTerms(legalText);
    }
  };

  useEffect(() => {
    getMenu(getMenuCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFileCallback = (response) => {
    const formattedModel = {
      ...userProfile,
      avatar: { url: `${URL_BASE_IMAGE}${response.data.data?.asset_url}`},
    };
    setUserProfile(formattedModel);
    turnOffLoading();
  };

  useEffect(() => {
    if (!!userData && Object.keys(userData)?.length) {
      const userToken = keycloak?.token;

      getFileService(
        MULTITENANT_CONSTANTS[TENANT].tenantServer,
        userData.avatar,
        userToken,
        getFileCallback
      );

      const formattedModel = userProfile;
      formattedModel.username = userData.username;
      formattedModel.firstName = userData.firstname;
      formattedModel.lastName = userData.lastname;
      formattedModel.email = userData.email;
      formattedModel.interests = userData.interests;
      formattedModel.ranking = "";
      formattedModel.loading = "";
      setUserProfile(formattedModel);
    }
  }, [userData]);

  const [checkoutURL, setCheckoutURL] = useState(null);

  const productCheckoutCallback = (data) => {
    setCheckoutURL(data.url);
  };

  useEffect(() => {
    if (!!keycloak && initialized) {
      const userToken = keycloak?.token;
      const username = keycloak?.tokenParsed?.preferred_username;

      if (!!username) {
        const body = { returnUrl: `${window.location.origin}/user` };
        getProductsDashboardService(
          MULTITENANT_CONSTANTS[TENANT].tenantServer,
          username,
          locale,
          userToken,
          body,
          productCheckoutCallback
        );
      }
    }
  }, [keycloak, initialized]);

  return (
    <div className="Perfil">
      <CustomModal showModal={showModal} modalMessage={modalMessage} />
      {userProfile !== null && (
        <div className="App-container">
          <Row className="Datos-usuario">
            <Col md={12} lg={12} className={"UserBlock"}>
              <Row style={{ width: "100%" }}>
                {userProfile?.avatar && (
                  <Col md={12} lg={2} className="UserAvatar">
                    <div className={"Avatar-imaxe-container"}>
                      <img
                        className={"Avatar-imaxe"}
                        alt={"Avatar"}
                        src={userProfile.avatar.url}
                        onClick={handleAvatarModal}
                      />
                    </div>
                    {MULTITENANT_CONSTANTS[TENANT].gamification && (
                      <div className="Avatar-points">
                        <img
                          className={"Icono-trophy"}
                          alt={"Icon"}
                          src={iconTrophy}
                        />
                        <span>{userProfile?.ranking?.points}</span>
                      </div>
                    )}
                  </Col>
                )}
                <Col md={12} lg={10} className={"UserInfo"}>
                  <Row className={"UserInfoHeader"}>
                    {userProfile?.username && (
                      <h3 className={"Username"}>{userProfile.username}</h3>
                    )}
                    {editUserInfo ? (
                      <div
                        onClick={handleEditUserInfo}
                        className={"EditionButton"}
                      >
                        <ion-icon name="close" />
                        <p>{t("perfil.edit.cancel")}</p>
                      </div>
                    ) : (
                      <FontAwesomeIcon
                        className="Link-datos"
                        icon={faEdit}
                        onClick={handleEditUserInfo}
                      />
                    )}
                  </Row>
                  <Row>
                    <UserInfoComponent
                      userProfile={userProfile}
                      editUserInfo={editUserInfo}
                      firstName={firstName}
                      handleSetFirstName={handleSetFirstName}
                      lastName={lastName}
                      handleSetLastName={handleSetLastName}
                      handleSendUserData={handleSendUserData}
                    />
                    <Col md={12} lg={6}>
                      {MULTITENANT_CONSTANTS[TENANT].showUserInterests && (
                        <SelectCategoriesComponent userProfile={userProfile} />
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <UserButtonOptionsComponent
              handlePasswordModal={handlePasswordModal}
              handleTermsModal={handleTermsModal}
              handleDisplayOnBoarding={handleDisplayOnBoarding}
              checkoutURL={checkoutURL}
              terms={terms}
              tutorialUrl={tutorialUrl}
              {...(!(MULTITENANT_CONSTANTS[TENANT]?.showDeleteUserButton === false) && { handleDeactivateUserModal })}
            />
          </Row>
          {!!MULTITENANT_CONSTANTS[TENANT].gamification && (
            <Row>
              <Col md={12} lg={7} className={"RewardsContainer"}>
                <Col className="Ranking-usuario">
                  <Row className={"RewardsHeader"}>
                    <h2 className={"BlockTitle Title-bold"}>
                      {t("perfil.insignias")}
                    </h2>
                    <NavLink
                      to={{ pathname: URL_RANKING }}
                      className={"Link-ranking"}
                    >
                      <img
                        className={"Icono-trophy"}
                        src={iconTrophy}
                        alt={"icon"}
                      />
                    </NavLink>
                  </Row>
                  <Row className={"Row-medals"}>
                    <AchievementComponent
                      renderTitle={true}
                      achievements={userProfile?.achievements}
                      rewards={userProfile?.rewards}
                    />
                  </Row>
                </Col>
              </Col>
            </Row>
          )}
        </div>
      )}
      {termsModal && (
        <TermsModalComponent
          refTerms={refTerms}
          handleTermsModal={handleTermsModal}
          terms={terms}
        />
      )}
      {deactivateModal && (
        <DesactivateUserModalComponent
          refModal={refDeactivate}
          handleDeactivateUserModal={handleDeactivateUserModal}
          handleDeactivateUser={handleDeactivateUser}
          username={username}
        />
      )}
      {passwordModal && (
        <PasswordModalComponent
          handleSendUserCredentials={handleSendUserCredentials}
          refPass={refPass}
          handlePasswordModal={handlePasswordModal}
          invalidPasswordOptions={invalidPasswordOptions}
          currentPassword={currentPassword}
          handleSetCurrentPassword={handleSetCurrentPassword}
          newPassword1={newPassword1}
          handleSetNewPassword1={handleSetNewPassword1}
          newPassword2={newPassword2}
          handleSetNewPassword2={handleSetNewPassword2}
          handleCheckNewPassword={handleCheckNewPassword}
          handleSetWrongCurrentPassword={handleSetWrongCurrentPassword}
          disabledPassEdit={disabledPassEdit}
        />
      )}
      {avatarModal && (
        <AvatarModalComponent
          refAvatar={refAvatar}
          handleSendAvatar={handleSendAvatar}
          handleAvatarModal={handleAvatarModal}
          userAvatar={userProfile.avatar}
        />
      )}
      {successEdit && (
        <AlertNotification
          type="success"
          showtitle={true}
          text={t("userupdate.password.success")}
        />
      )}
      {samePassword && (
        <AlertNotification
          type="error"
          showtitle={true}
          text={t("register.samePassword")}
        />
      )}
      {passwordsMustMatch && (
        <AlertNotification
          type="error"
          showtitle={true}
          text={t("register.invalidpassword2")}
        />
      )}
      {wrongCurrentPassword && (
        <AlertNotification
          type="error"
          showtitle={true}
          text={t("userupdate.wrongCurrentPassword")}
        />
      )}
      {invalidNewPassword && (
        <AlertNotification
          type="error"
          showtitle={true}
          text={t("userupdate.invalidNewPassword")}
        />
      )}
      {failedNewPassword && (
        <AlertNotification
          type="error"
          showtitle={true}
          text={t("userupdate.failedNewPassword")}
        />
      )}
      {showErrorModal && (
        <ErrorMessageModalComponent
          textTitle={errorText}
          textBody={errorSubText || "error_subtitle"}
          onClose={() => {setShowErrorModal(false); setErrorSubText("");}}
        ></ErrorMessageModalComponent>
      )}
    </div>
  );
};

export default WithAuthorization(UserProfile);


import React, { useState } from "react";
import { IMAGE_HEIGHT, IMAGE_WIDTH } from "../../CatalogueConstants";
import { getWidgetSubtitle, getWidgetTitle } from "../../../app/src/Utils/utils";
import style from "./style.module.css";
import MetaData from "../../../app/src/Components/widget_v2/components/MetaData/MetaData";
import GalleryCardComponentV2 from "../../../app/src/Components/common/GalleryCardComponentV2";
import GalleryCardComponent from "../../../app/src/Components/common/GalleryCardComponent";

const ContentCardComponentV2 = ({ content, handleSelectedMedia, imageCard, category }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className="PlaylistItemContainer VideoContainer"
      key={content.id}
      onClick={() => handleSelectedMedia(content.id)}
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
    >
      <div className={style.playlistItemContent}>
        <div className={style.playlistItemImage}>
          <GalleryCardComponentV2
            image={imageCard}
            width={IMAGE_WIDTH}
            height={IMAGE_HEIGHT}
            contentType={content.type?.name}
            tags={content.tags}
            containerIsHover={isHover}
          />
        </div>
        <div className={style.playlistItemFooter}>
          <MetaData category={category} startTimeMs={content?.start * 1000} />
          <div className={style.playlistItemTitle}>{getWidgetTitle(content)}</div>
          <div className={style.playlistItemSubtitle}>{getWidgetSubtitle(content)}</div>
        </div>
      </div>
    </div>
  );
};

export default ContentCardComponentV2;
